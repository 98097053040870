import { FontInfo } from "./FontTypes";

const loadedFonts = new Set<string>();
export const newFonts = [
  {
    family: "Archivo",
    load: () =>
      import("@remotion/google-fonts/Archivo") as unknown as Promise<FontInfo>,
  },
  {
    family: "Inter",
    load: () =>
      import("@remotion/google-fonts/Inter") as unknown as Promise<FontInfo>,
  },
  {
    family: "Kanit",
    load: () =>
      import("@remotion/google-fonts/Kanit") as unknown as Promise<FontInfo>,
  },
  {
    family: "Lato",
    load: () =>
      import("@remotion/google-fonts/Lato") as unknown as Promise<FontInfo>,
  },
  {
    family: "Lora",
    load: () =>
      import("@remotion/google-fonts/Lora") as unknown as Promise<FontInfo>,
  },
  {
    family: "Merriweather",
    load: () =>
      import(
        "@remotion/google-fonts/Merriweather"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Montserrat",
    load: () =>
      import(
        "@remotion/google-fonts/Montserrat"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Noto Sans",
    load: () =>
      import("@remotion/google-fonts/NotoSans") as unknown as Promise<FontInfo>,
  },
  {
    family: "Nunito",
    load: () =>
      import("@remotion/google-fonts/Nunito") as unknown as Promise<FontInfo>,
  },
  {
    family: "Nunito Sans",
    load: () =>
      import(
        "@remotion/google-fonts/NunitoSans"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Open Sans",
    load: () =>
      import("@remotion/google-fonts/OpenSans") as unknown as Promise<FontInfo>,
  },
  {
    family: "Oswald",
    load: () =>
      import("@remotion/google-fonts/Oswald") as unknown as Promise<FontInfo>,
  },
  {
    family: "PT Sans",
    load: () =>
      import("@remotion/google-fonts/PTSans") as unknown as Promise<FontInfo>,
  },
  {
    family: "Playfair Display",
    load: () =>
      import(
        "@remotion/google-fonts/PlayfairDisplay"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Poppins",
    load: () =>
      import("@remotion/google-fonts/Poppins") as unknown as Promise<FontInfo>,
  },
  {
    family: "Raleway",
    load: () =>
      import("@remotion/google-fonts/Raleway") as unknown as Promise<FontInfo>,
  },
  {
    family: "Roboto",
    load: () =>
      import("@remotion/google-fonts/Roboto") as unknown as Promise<FontInfo>,
  },
  {
    family: "Roboto Condensed",
    load: () =>
      import(
        "@remotion/google-fonts/RobotoCondensed"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Roboto Mono",
    load: () =>
      import(
        "@remotion/google-fonts/RobotoMono"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Roboto Slab",
    load: () =>
      import(
        "@remotion/google-fonts/RobotoSlab"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Rubik",
    load: () =>
      import("@remotion/google-fonts/Rubik") as unknown as Promise<FontInfo>,
  },
  {
    family: "Ubuntu",
    load: () =>
      import("@remotion/google-fonts/Ubuntu") as unknown as Promise<FontInfo>,
  },
  {
    family: "Work Sans",
    load: () =>
      import("@remotion/google-fonts/WorkSans") as unknown as Promise<FontInfo>,
  },
  {
    family: "Bricolage Grotesque",
    load: () =>
      import(
        "@remotion/google-fonts/BricolageGrotesque"
      ) as unknown as Promise<FontInfo>,
  },
  {
    family: "Libre Franklin",
    load: () =>
      import(
        "@remotion/google-fonts/LibreFranklin"
      ) as unknown as Promise<FontInfo>,
  },
];

export const loadFont = async (fontFamily: string) => {
    if (loadedFonts.has(fontFamily)) {
      // console.log(`Font already loaded: ${fontFamily}`);
      return fontFamily;
    }
  
    const fontToLoad = newFonts.find((font) => font.family === fontFamily);
    if (fontToLoad) {
      try {
        const loaded = await fontToLoad.load();
        const { fontFamily: loadedFontFamily } = loaded.loadFont();
        loadedFonts.add(loadedFontFamily);
        return loadedFontFamily;
      } catch (error) {
        console.error(`Error loading font ${fontFamily}:`, error);
      }
    }
    return null;
  };