import {isRouteErrorResponse, Link, useRouteError} from "@remix-run/react";
import {NotFound} from "~/components/AppShell/NotFound";
import {IconHeartBroken, IconHome} from "@tabler/icons-react";
import {Button, Code} from "@mantine/core";
import {getRoute} from "~/routes";
import { HelpButton } from "../HelpButton";

export function MyError({error}) {
  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  // Handle other types of errors
  // @ts-ignore
  return (
    <div>
      {<IconHeartBroken size={"30px"} color={"red"}></IconHeartBroken>}
      <h1>
        Oops! Something went wrong. 
      </h1>
      <p>{error?.data && <Code>{error?.data}</Code>}</p>
      <p>
        We're sorry, but an unexpected error occurred. Please try again later or get help: {<HelpButton />}
        <br />
      </p>

      <Button
        component={Link}
        to={getRoute("home")}
        size="md"
        rightSection={<IconHome size={"1rem"} />}
      >
        Take me back to home page
      </Button>
    </div>
  );
}