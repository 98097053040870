import { EditorState } from "../../AppContent/state/EditorStateContext";
// import { getRoute } from "~/routes";


interface HandleFileChangeParams {
  file: File;
  uploadType: string;
  editorState: EditorState;
  setIsUploading?: (value: boolean) => void;
  setUploadProgress?: (value: number) => void;
  fetcher: any;
}

export function handleFileChange({
  file,
  uploadType,
  editorState,
  setIsUploading,
  setUploadProgress,
  fetcher
}: HandleFileChangeParams) {
  if (file) {
    setIsUploading?.(true);
    setUploadProgress?.(0);

    const formData = new FormData();
    formData.append("filename", file.name);
    formData.append("contentType", file.type);
    formData.append("fileSize", file.size.toString());
    formData.append("uploadType", uploadType);

    if (editorState.videoId) {
      formData.append("videoId", editorState.videoId.toString());
    }

    fetcher.submit(formData, { 
      method: "post", 
      action: "/upload" // we cant use import from routes.ts here because this file is used in the lambda function
      // and it will cause a circular dependency. we only upload /remotion folder to the lambda function, 
      // so we need to use a hardcoded path instead of using getRoute function.
    });
  }
}