import { CardWithStatsIndex } from "./FramesComponents/CardWithStats/CardWithStatsIndex";
import { Frame1Index } from "./FramesComponents/Frame1/Frame1Index";
import { Frame2Index } from "./FramesComponents/Frame2/Frame2Index";
import { Frame3Index } from "./FramesComponents/Frame3/Frame3Index";
import { Frame4Index } from "./FramesComponents/Frame4/Frame4Index";
import { Frame6Index } from "./FramesComponents/Frame6/Frame6Index";
import { Frame7Index } from "./FramesComponents/Frame7/Frame7Index";
import { Frame8Index } from "./FramesComponents/Frame8/Frame8Index";
import { LogosCircle } from "./FramesComponents/LogosCircle/LogosCircleIndex";
import { TypingSuggestionSpotlight } from "./FramesComponents/TypingSuggestionSpotlight/TypingSuggestionSpotlightIndex";

export const allFrames = {
  //   Key (e.g. "featuresLeftBrowserMockupRight") is the frameId we are using in the Editor.
  //   We access allFrames when we add a component (we populate the frame content with defaultContent) and display thumbnails in the gallery.
  //   We access allFrames also in the Preview+Timeline: we display the tsx "component" there.
  intoLogoAndTitle: {
    thumbnails: {
      square: "https://public-assets.paracast.io/thumb-intro-50.mp4",
      horizontal: "https://public-assets.paracast.io/thumb-intro-50.mp4",
      vertical: "https://public-assets.paracast.io/thumb-intro-50.mp4",
    },
    defaultDurationInFrames: 80,
    purpose: "Opening frame to introduce the product/brand",
    bestUsedFor: "Beginning of the video",
    timing: "Usually first frame",
    description: "Displays logo and product name with a clean, professional animation",
    defaultContent: {
      projectTitle: {
        type: "text",
        value: "Paracast.io",
        description: "Company/product name (1-2 words)"
      },
      image1: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
        description: "Company logo (preferably transparent PNG)"
      },
    },
    component: Frame2Index,
  },
  featuresLeftBrowserMockupRight: {
    thumbnails: {
      square: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
      horizontal: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
      vertical: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
    },
    defaultDurationInFrames: 400,
    purpose: "Showcase product features alongside a live demo",
    bestUsedFor: "Main product demonstration and feature highlights",
    timing: "Core part of the video, after introduction",
    description: "Split screen with features list and browser demo",
    defaultContent: {
      text1: { 
        type: "text", 
        value: "Freshen up your SaaS marketing",
        description: "Main headline (4-6 words)"
      },
      text2: { type: "text", value: "Make awesome job" },
      projectTitle: { type: "text", value: "Paracast.io" },
      projectDomainName: { type: "text", value: "paracast.io" },
      video1: {
        type: "video",
        value: "https://public-assets.paracast.io/featuresLeftBrowserMockupRight-50.mp4",
        description: "Product demo video"
      },
      image1: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
      },
      image2: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
      },
      list1: {
        type: "list-text-only",
        value: [
          { text: "Make stunning videos" },
          { text: "Unlimited generations" },
          { text: "Grow your socials" },
          { text: "No editing needed" }
        ],
        description: "Key features (4 items recommended)"
      },
    },
    component: Frame1Index,
  },
    finalLogoCtaAndTitle: {
      thumbnails: {
        square: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
        horizontal: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
        vertical: "https://public-assets.paracast.io/finalLogoCtaAndTitle-50.mp4",
      },
      defaultDurationInFrames: 170,
      purpose: "Strong closing with call-to-action",
      bestUsedFor: "Video conclusion and viewer activation",
      timing: "Final frame of the video",
      description: "Logo, product name, and compelling CTA",
      defaultContent: {
        text1: { 
          type: "text", 
          value: "Try it today!",
          description: "Call to action (2-4 words)"
        },
        projectTitle: { 
          type: "text", 
          value: "Paracast.io",
          description: "Company/product name"
        },
        image1: {
          type: "image",
          value: "https://public-assets.paracast.io/paracast-org_logo.png",
        }
      },
      component: Frame3Index,
  },
  singleTestimonialAndAudience: {
    thumbnails: {
      square: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
      horizontal: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
      vertical: "https://public-assets.paracast.io/singleTestimonialAndAudience-50.mp4",
    },
    defaultDurationInFrames: 260,
    purpose: "Share social proof and target audience",
    bestUsedFor: "Building credibility and audience connection",
    timing: "After features, before call-to-action",
    description: "Testimonial with author info and target audience list",
    defaultContent: {
      text1: { 
        type: "text", 
        value: "Zeng",
        description: "Author name"
      },
      text2: { 
        type: "text", 
        value: "🤯 Mind-blowing AI alert! Just discovered @paracast_io by @alexanderisorax - the AI magician that turns websites into stunning videos!",
        description: "Testimonial text (under 150 characters)"
      },
      image1: {
        type: "image",
        value: "https://public-assets.paracast.io/alexanderisora_profile_pic_400x400.jpg",
      },
      text3: { type: "text", value: "Made", description: "Keep it one word." },
      text4: { type: "text", value: "exclusively", description: "Keep it one adverb word." },
      text5: { type: "text", value: "for", description: "A preposition. Keep it one word." },
      list1: {
        type: "list-text-only",
        value: [
          { text: "Startups" },
          { text: "Makers" },
          { text: "Founders" },
          { text: "Creators" },
          { text: "Marketers" },
        ],
        description: "Target audiences (4-5 items)"
      },
    },
    component: Frame4Index,
  },
  floatingCodeCard: {
    thumbnails: {
      square: "https://public-assets.paracast.io/Frame6Index-50-opti.mp4",
      horizontal: "https://public-assets.paracast.io/Frame6Index-50-opti.mp4",
      vertical: "https://public-assets.paracast.io/Frame6Index-50-opti.mp4",
    },
    defaultDurationInFrames: 150,
    purpose: "Display code snippets in an engaging way",
    bestUsedFor: "Technical products, developer tools, or API demonstrations",
    timing: "When explaining technical features or implementation",
    description: "Animated floating card with syntax-highlighted code",
    defaultContent: {
      code: { 
        type: "text", 
        value: `function animateCode(progress) {
          const frames = 60;
          const step = Math.floor(progress * frames);
          
          for (let i = 0; i < step; i++) {
            renderFrame(i);
          }
          
          return "Animation complete!";
        }`,
        description: "Code snippet (preferably under 10 lines)"
      },
    },
    component: Frame6Index,
  },

  basicText: {
    thumbnails: {
      square: "https://public-assets.paracast.io/basicText-50.mp4",
      horizontal: "https://public-assets.paracast.io/basicText-50.mp4",
      vertical: "https://public-assets.paracast.io/basicText-50.mp4",
    },
    defaultDurationInFrames: 70,
    purpose: "Display a single, impactful message",
    bestUsedFor: "Key messages, transitions, or emphasizing important points",
    timing: "Can be used throughout the video",
    description: "Simple text display with clean animation, perfect for USP or key message",
    defaultContent: {
      text1: {
        type: "text",
        value: "Make videos without making video",
        description: "Short, impactful message (3-7 words)"
      },
    },
    component: Frame7Index,
  },
  basicImage: {
    thumbnails: {
      square: "https://public-assets.paracast.io/Frame8Index-50-comp.mp4",
      horizontal: "https://public-assets.paracast.io/Frame8Index-50-comp.mp4",
      vertical: "https://public-assets.paracast.io/Frame8Index-50-comp.mp4",
    },
    defaultDurationInFrames: 230,
    purpose: "Showcase a single image or screenshot",
    bestUsedFor: "Product screenshots, UI demonstrations, or visual examples",
    timing: "After introducing a feature or concept that needs visual proof",
    description: "Full-frame image display with subtle animation",
    defaultContent: {
      img1: { 
        type: "image", 
        value: "https://temp.paracast.io/1730975468142-screencapture-paracast-io-2024-11-07-14_30_46.png",
        description: "High-quality screenshot or product image"
      },
    },
    component: Frame8Index,
  },

  CardWithStats: {
    thumbnails: {
      square: "https://public-assets.paracast.io/CardWithStats-50.mp4",
      horizontal: "https://public-assets.paracast.io/CardWithStats-50.mp4",
      vertical: "https://public-assets.paracast.io/CardWithStats-50.mp4",
    },
    defaultDurationInFrames: 130,
    purpose: "Highlight important metrics or statistics",
    bestUsedFor: "Showing growth, impact, or key performance indicators",
    timing: "After establishing product value, to provide proof",
    description: "Animated card with two key statistics",
    defaultContent: {
      number1: { 
        type: "text", 
        value: "153",
        description: "Primary statistic"
      },
      number2: { 
        type: "text", 
        value: "10%",
        description: "Secondary statistic"
      },
      text1: { 
        type: "text", 
        value: "Total orders",
        description: "Stat description"
      },
      projectLogo: { 
        type: "image", 
        value: "https://public-assets.paracast.io/paracast-org_logo.png"
      },
    },
    component: CardWithStatsIndex,
  },
  TypingSuggestionSpotlight: {
    thumbnails: {
      square: "https://public-assets.paracast.io/TypingSuggestionSpotlight-50.mp4",
      horizontal: "https://public-assets.paracast.io/TypingSuggestionSpotlight-50.mp4",
      vertical: "https://public-assets.paracast.io/TypingSuggestionSpotlight-50.mp4",
    },
    defaultDurationInFrames: 180,
    purpose: "Demonstrate interactive typing suggestions",
    bestUsedFor: "Showcasing search or autocomplete functionality",
    timing: "When demonstrating user interaction features",
    description: "Interactive typing suggestion animation with spotlight effect",
    defaultContent: {
      text1: { 
        type: "text", 
        value: "add email integration",
        description: "Search or input text example"
      },
      img1: { 
        type: "image", 
        value: "https://temp.paracast.io/1730975468142-screencapture-paracast-io-2024-11-07-14_30_46.png",
        description: "Background screenshot or context image"
      },
      projectLogo: {
        type: "image",
        value: "https://public-assets.paracast.io/alexanderisora_profile_pic_400x400.jpg",
        description: "Company or product logo"
      },
      list1: {
        type: "list-text-with-secondary-and-image",
        value: [
          {
            text: "Loops",
            secondaryText: "YC baked email SaaS",
            image: "https://public-assets.paracast.io/loops.jpg",
          },
          {
            text: "Mailgun",
            secondaryText: "Reliable transactional emails",
            image: "https://public-assets.paracast.io/mailgun.png",
          },
          {
            text: "Resend",
            secondaryText: "Stylish email service",
            image: "https://public-assets.paracast.io/resend.jpg",
          },
          {
            text: "Sendgrid",
            secondaryText: "Old and honorable",
            image: "https://public-assets.paracast.io/sendgrid-2.png",
          },
          {
            text: "AWS SES",
            secondaryText: "Geeky, but cheap way",
            image: "https://public-assets.paracast.io/aws-2.png",
          },
        ],
        description: "Suggestion items with images and descriptions"
      },
    },
    component: TypingSuggestionSpotlight,
  },
  LogosCircle: {
    thumbnails: {
      square: "https://public-assets.paracast.io/LogosCircle-50.mp4",
      horizontal: "https://public-assets.paracast.io/LogosCircle-50.mp4",
      vertical: "https://public-assets.paracast.io/LogosCircle-50.mp4",
    },
    defaultDurationInFrames: 180,
    purpose: "Display multiple logos in an engaging circular animation",
    bestUsedFor: "Showing integrations, clients, or supported platforms",
    timing: "After mentioning partnerships or compatibility",
    description: "Animated circle of logos with smooth rotation",
    defaultContent: {
      projectLogo: {
        type: "image",
        value: "https://public-assets.paracast.io/paracast-org_logo.png",
        description: "Main company logo at center"
      },
      list1: {
        type: "list-image-only",
        value: [
          { image: "https://public-assets.paracast.io/product-hunt-logo-orange-960.png" },
          { image: "https://public-assets.paracast.io/icons8-facebook-480.png" },
          { image: "https://public-assets.paracast.io/instagram-new-2016.png" },
          { image: "https://public-assets.paracast.io/linkedin-icon.png" },
          { image: "https://public-assets.paracast.io/twitter-x.png" },
        ],
        description: "Collection of partner/integration logos (5-6 recommended)"
      },
    },
    component: LogosCircle,
  },
};
